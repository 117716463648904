exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/AboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-call-to-action-tsx": () => import("./../../../src/pages/CallToAction.tsx" /* webpackChunkName: "component---src-pages-call-to-action-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-features-section-tsx": () => import("./../../../src/pages/FeaturesSection.tsx" /* webpackChunkName: "component---src-pages-features-section-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/Features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-footer-tsx": () => import("./../../../src/pages/Footer.tsx" /* webpackChunkName: "component---src-pages-footer-tsx" */),
  "component---src-pages-header-tsx": () => import("./../../../src/pages/Header.tsx" /* webpackChunkName: "component---src-pages-header-tsx" */),
  "component---src-pages-hero-tsx": () => import("./../../../src/pages/Hero.tsx" /* webpackChunkName: "component---src-pages-hero-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/HowItWorks.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/Pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */)
}

